@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400&display=swap");

.container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.page-content {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 1400px;
  margin: 0 auto;
}

.img-full {
  width: 100%;
}

.card-container {
  padding: 25px 0;
}

.vid-row video {
  width: 100%;
  max-width: 1600px;
  align-self: center;
}
