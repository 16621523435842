#hero-container {
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -25px;
  max-height: 500px;
  overflow: clip;
}

@media (min-width: 601px) {
  .mobile-photos {
    display: none;
  }

  #orders {
    padding-bottom: 25px;
  }
}

#hero {
  filter: brightness(110%);
  margin: auto;
}
.orders-text {
  font-family: "Montserrat", sans-serif;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}

#orders-now {
  border: solid 3px #cea164;
  border-radius: 6px;
  width: auto !important;
  text-decoration: none;
  padding: 10px 40px;
  margin: 15px auto;
  letter-spacing: 5px;
  font-size: 1.75em;
  color: #cea164;
  transition: all 500ms;
}

#orders-now:hover {
  cursor: pointer;
  background: #cea164;
  color: #fff;
}

#orders-limited {
  letter-spacing: 5px;
  font-size: 0.75em;
  color: #333;
}

@media (max-width: 600px) {
  #orders-now {
    margin-top: 25px;
    letter-spacing: 4px;
    padding: 10px 25px;
    font-size: 1.1em;
  }

  #orders-limited {
    letter-spacing: 4px;
    font-size: 0.8em;
  }
}
