#formContainer {
  max-width: 600px;
  margin: 0 auto;
}

#formHeading {
  font-size: 2em;
  font-weight: 100;
  letter-spacing: 1px;
  padding-bottom: 15px;
}

.grayRow {
  background: #f5f5f5;
  padding: 15px 0;
  margin: 0;
}

.w-100 {
  width: 100%;
  padding-top: 5px;
}

.img-row {
  margin-top: 25px;
  text-align: center;
}
