#ReactGridGallery {
  text-align: center;
}

.ReactGridGallery_tile {
  float: none !important;
  display: inline-block;
  margin: 0 3px !important;
}

h4 {
  text-align: center;
  font-size: 2em;
  font-weight: 100;
  margin: 25px 0 30px;
}

.project-grid {
  display: grid;
  gap: 36px;
  padding: 0 16px 36px;
}
@media (min-width: 800px) {
  .project-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.project-main-image {
  object-fit: cover;
  width: 100%;
}

.project-main-image-wrap:hover {
  filter: brightness(0.85);
}

.project-main-image-wrap {
  border-radius: 4px;
  background: #fff;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: filter 0.2s;
}

@media (max-width: 580px) {
  .ReactGridGallery_tile-viewport {
    height: auto !important;
  }
  .ReactGridGallery_tile-viewport img {
    width: 100%;
    height: auto !important;
  }

  .ReactGridGallery_tile {
    display: block;
  }
}
