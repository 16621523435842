#about-img-top {
    padding-bottom: 20px;
}

p {
    font-size: 1.1em;
}

.first-p::first-letter {
    float: left;
    font-family: Georgia, serif;
    font-size: 55px;
    line-height: 50px;
    padding-right: 6px;
}