.contact-img-row {
  text-align: center;
  padding: 45px 0;
}

.contact-img-row img {
  width: 100%;
}

.contact-container {
  padding: 45px 0 25px 0;
  text-align: center;
}

iframe {
  border: 0;
}

@media (max-width: 860px) {
  .contact-img-row {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}
