#supersport-vid {
  margin: -25px auto 0;
}

.container-ss {
  max-width: 1200px;
  margin: 0 auto;
}

.supersport-img-col {
  margin-bottom: 30px;
}

#pdf-button {
  border: solid 3px #cea164;
  border-radius: 6px;
  width: auto !important;
  text-decoration: none;
  padding: 10px 40px;
  margin: 15px auto;
  letter-spacing: 5px;
  font-size: 1.75em;
  color: #cea164;
  transition: all 500ms;
  color: #cea164;
  margin: 20px auto 40px;
}

#pdf-button:hover {
  cursor: pointer;
  background: #cea164;
  color: #fff;
}

@media (min-width: 991px) {
  .img-right {
    float: right;
    max-width: 750px;
    margin-left: 15px;
  }

  .img-left {
    float: left;
    max-width: 750px;
    margin-right: 15px;
  }
}
