#colorName {
  font-size: 2.6em;
  letter-spacing: 4px;
  color: #4d4d4d;
  text-align: center;
  padding-bottom: 75px;
  margin-top: -40px;
}

.colorRow img:hover {
  cursor: pointer;
  opacity: 0.5;
}

.colorRow {
  padding-bottom: 65px;
}

.colorRow .col {
  min-width: 12%;
}

@media (max-width: 1150px) {
  #colorName {
    font-size: 2em;
    letter-spacing: 3px;
    padding-bottom: 50px;
    margin-top: 0;
  }

  .colorRow .col {
    min-width: 25%;
    max-width: 50%;
  }
}

@media (max-width: 500px) {
  .colorRow .col {
    min-width: 50%;
  }
}
